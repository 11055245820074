<template>
  <v-card class="pa-2 ma-2">
    <v-layout class="pb-4">
      <v-flex lg1>
        <v-btn tile outlined color="primary" class="ml-2 mr-4 mt-3" @click="$router.go(-1)"><v-icon>mdi-arrow-left</v-icon></v-btn>
      </v-flex>
      <v-flex class="d-flex">
        <v-hover v-slot="{ hover }">
          <div class="mr-6">
            <v-avatar size="55">
              <img src="https://cdn-icons-png.flaticon.com/128/1177/1177568.png" alt="" srcset="">
            </v-avatar>
            <v-icon style="postition: absolute;" v-if="hover" class="mt-1 ml-n10">mdi-camera</v-icon>
          </div>
        </v-hover>
        <v-card-title class="mx-1 py-0 mt-4"> {{userObj._id ? 'Update User' : 'Create User'}} </v-card-title>
      </v-flex>
      <v-spacer></v-spacer>
      <v-btn color="success" class="mr-4" @click="saveRecordHandler" tile> {{ userObj._id ? 'Update' : 'Save'}} </v-btn>
    </v-layout>
    <v-divider></v-divider>
    <form-renderer v-if="roleObj.formid" ref="form" :formId="roleObj.formid" :modelObj.sync="userObj" @formLoaded="getSingleRecordHandler"></form-renderer>
    <v-dialog v-model="passwordDialog" absolute bottom temporary right width="500px">
      <v-card elevation="0" height="100%">
        <v-card-title class="primary white--text px-4">
          <span class="title"> Create Password </span>
        </v-card-title>
        <v-divider></v-divider>
        <v-form>  
          <v-card-text>
            <v-row class="mx-4">
              <v-col cols="12">
                <v-text-field outlined dense hide-details label="Password" v-model="userObj.password" :rules="setValidation('requiredValidation')"></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
        </v-form>
        <v-divider></v-divider>
        <v-card-actions>
          <v-layout class="d-flex justify-center">
            <v-btn tile color="success" class="mx-2" @click="SaveUpdateHandler"> Save </v-btn>
            <v-btn tile @click="passwordDialog= false" color="error">Cancel</v-btn>
          </v-layout>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>
<script>
export default {
  data () {
    return {
      userId: this.$route.params.id,
      roleId: this.$route.params.role_id,
      roleObj: {},
      valid: false,
      userObj: {
        formdata: {}
      },
      formFields: [],
      passwordDialog: false
    }
  },
  mounted () {
    this.getListOfUserRoles()
  },
  components: {
    'form-renderer': () => import('@/components/FormRender.vue'),
  },
  methods: {
    getListOfUserRoles () {
      this.$_execute('get', `user_roles/${this.roleId}`)
        .then(({ data } = {}) => {
          this.roleObj = data
        })
    },
    saveRecordHandler() {
      if (this.$refs.form.$refs.form.$refs.form.validate()) {
        if (this.userObj._id) this.SaveUpdateHandler()
        else this.passwordDialog = true
      } else this.$root.$emit('snackbar', { message: 'Validation Issue', color: 'error' })
    },
    SaveUpdateHandler() {
      const url = this.userId ? `users/${this.userId}` : 'signup'
      const model = this.$_formatModelForSelectField(this.$_copy(this.$refs.form.fields), this.$_copy(this.userObj), true)
      model.role = this.roleObj._id
      model.rolecode = this.roleObj.code
      this.$_execute((this.userId ? 'put' : 'post'), url, model)
        .then(({ data } = {}) => {
          if (!this.userId) this.$router.push(`/users_roles/${data.role}/users/actions/${data._id}`)
          this.$root.$emit('snackbar', {
            message: (this.userId) ? 'Updated Successfully' : 'Saved Successfully',
            color: 'success'
          })
        })
    },
    getSingleRecordHandler () {
      if (this.userId) {
        this.$_execute('get', `users/${this.userId}`).then(({ data }) => {
          data = this.$_formatModelForSelectField(this.$_copy(this.$refs.form.fields), data, false)
          this.userObj = data
        })
      }
    }
  }
}
</script>
